body{
  height: 100%;
  background: #FAFAFA !important;
} 
.container{
  height: 100%;
}
.footer{
  height: 48px;
    background: #80bd38;
    color: #fff;
    padding: 12px 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}
.radio{
  accent-color: #80BD38;
}
.App {
  text-align: center;
  height: 100% !important;
}
@font-face {
  font-family: 'Avenir';

  src: local('Avenir'), url('./static/fonts/Avenir.ttc') format('TrueType');
}

@font-face {
  font-family: 'Avenir black';
  font-style: normal;
  font-weight: 600;
  src: local('Avenir'), url('./static/fonts/Avenir.ttc') format('TrueType');
}
@font-face {
  font-family: 'qtzaintext';
  font-style: normal;
  font-weight: normal;
  src: local('qtzaintext'), url('./static/fonts/qtzaintext.otf') format('opentype');
}

@font-face {
  font-family: 'zainfontbold';
  font-style: normal;
  font-weight: normal;
  src: local('zainfontbold'), url('./static/fonts/Zain-Bold.ttf') format('TrueType');
}

@font-face {
  font-family: 'zainfontregular';
  font-style: normal;
  font-weight: normal;
  src: local('zainfontregular'), url('./static/fonts/Zain-Regular.ttf') format('TrueType');
}

@font-face {
  font-family: 'zainfontlight';
  font-style: normal;
  font-weight: normal;
  src: local('zainfontlight'), url('./static/fonts/Zain-Light.ttf') format('TrueType');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container{
  --bs-gutter-x: 1.9rem !important;
}

.title{
  text-align: center;
  color: #80BD38;
  font-family: Avenir;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn{
  background:#80bd38 !important;
  border-color: #80bd38 !important;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  #download-button {
    position:fixed;
    bottom:0;
    left: 0;
    font-size:14px;
    width:100%;
    height: 60px;
    font-weigt:normal;
    background-color: #FFFFFF;
  }
  .bb{
    width: 90%;
    margin: 10px;
  }
}

.bg-home::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(./assets/img/back-ground.svg);
  background-position: right top;
  background-size: 250px;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -100;
}
.page-up {
  margin-top: 100px !important;
}
.header{
  height: 60px;
  background-color: #fafafa;
  padding: 12px 5px;
  border-bottom: 1px solid #e6e6e6;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}

.last-input{
  margin-bottom: 67px !important;
}
